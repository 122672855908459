* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    
  }